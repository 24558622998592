var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      !_vm.times || (!_vm.displayZeroValue && !_vm.times.totalTime)
        ? _c("fd-value-display", {
            attrs: {
              "no-value-text": _vm.noValueText,
              "no-value-key": _vm.noValueKey,
              "no-value-style": _vm.computedNoValueStyle,
              "no-value-class": _vm.noValueClass
            }
          })
        : !_vm.times.showAdvanced
        ? _c("fd-value-display", {
            style: _vm.computedStyle,
            attrs: { value: _vm.times.summaryString }
          })
        : !!_vm.singleLine
        ? _c(
            "v-row",
            {
              style: _vm.computedStyle,
              attrs: { dense: "", "no-gutters": "" }
            },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                !!_vm.times.regularTime
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.times.regularTime.toFixed(2))),
                      _c(
                        "strong",
                        { staticClass: "fd-time-display-identifier" },
                        [_vm._v("s")]
                      )
                    ])
                  : _vm._e(),
                !!_vm.times.overTime
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.times.overTime.toFixed(2))),
                      _c(
                        "strong",
                        { staticClass: "fd-time-display-identifier" },
                        [_vm._v("o")]
                      )
                    ])
                  : _vm._e(),
                !!_vm.times.doubleTime
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.times.doubleTime.toFixed(2))),
                      _c(
                        "strong",
                        { staticClass: "fd-time-display-identifier" },
                        [_vm._v("d")]
                      )
                    ])
                  : _vm._e()
              ])
            ],
            1
          )
        : !_vm.singleLine
        ? _c(
            "v-container",
            {
              staticClass: "pa-0",
              style: _vm.computedStyle,
              attrs: { fluid: "" }
            },
            [
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "" } },
                [
                  !!_vm.times.regularTime
                    ? _c("v-col", { attrs: { cols: "12" } }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.times.regularTime.toFixed(2))
                        ),
                        _c(
                          "strong",
                          { staticClass: "fd-time-display-identifier" },
                          [_vm._v("s")]
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "" } },
                [
                  !!_vm.times.overTime
                    ? _c("v-col", { attrs: { cols: "12" } }, [
                        _vm._v(
                          "\n        " + _vm._s(_vm.times.overTime.toFixed(2))
                        ),
                        _c(
                          "strong",
                          { staticClass: "fd-time-display-identifier" },
                          [_vm._v("o")]
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "" } },
                [
                  !!_vm.times.doubleTime
                    ? _c("v-col", { attrs: { cols: "12" } }, [
                        _vm._v(
                          "\n        " + _vm._s(_vm.times.doubleTime.toFixed(2))
                        ),
                        _c(
                          "strong",
                          { staticClass: "fd-time-display-identifier" },
                          [_vm._v("d")]
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }