import FDVue from "@fd/lib/vue";
import { mapMutations } from "vuex";
import {
  loginService,
  accessCodeService,
  environmentService,
  personService,
  notificationService,
  ContactMethod
} from "@fd/current/client/services";
import { AccessCodeChallenge, LoginChallenge, LoginInformation } from "@fd/current/client/services";
import { getLogin, setLogin as setLoginInformation } from "@fd/current/client/login";
import { pause } from "@fd/lib/client-util/util";
import { hmacSha512 } from "@fd/lib/client-util/encryption";
import { VForm } from "@fd/lib/vue/types";
import rules from "@fd/lib/vue/rules";
import errorHandling, { ErrorHandlingOverride } from "@fd/lib/vue/mixins/errorHandling";
import * as DateUtil from "@fd/lib/client-util/datetime";
import loginBranding from "../mixins/loginBranding";

enum LoginStages {
  EnterEmailOnLoad,
  StartOver,
  EnterPassword,
  EnterAccessCode,
  SelectDifferentContactMethod,
  EnterNewPassword,
  NewPasswordUpdated,
  TermsAndConditions,
  AllowNotifications,
  TermsAndConditionsAccepted,
  LoggingIn
}
type StateManager = {
  didForgetPassword: boolean;
  didUpdatePassword: boolean;
  requiresActivation: boolean;
  isNewAccount: boolean;
  requiresUpdatedTermsAndConditions: boolean;
  hasCheckedTermsAndConditions: boolean;
};

export default FDVue.extend({
  name: "fd-Login",

  mixins: [rules, errorHandling, loginBranding],

  components: {
    "fd-privacy-dialog": () => import("@fd/current/client/views/components/PrivacyDialog.vue"),
    "fd-terms-dialog": () => import("@fd/current/client/views/components/TermsDialog.vue"),
    "fd-code-entry": () => import("@fd/lib/vue/components/CodeEntry.vue"),
    "fd-login-select-different-contact-method-form": () =>
      import("./components/LoginSelectDifferentContactMethodSection.vue"),
    "fd-login-terms-and-conditions-form": () =>
      import("./components/LoginTermsAndConditionsSection.vue"),
    "fd-login-allow-notifications-form": () =>
      import("./components/LoginAllowNotificationsSection.vue"),
    "fd-login-terms-and-conditions-accepted-form": () =>
      import("./components/LoginTermsAndConditionsAcceptedSection.vue")
  },

  watch: {
    loginStage(newValue, oldValue) {
      console.log(`loginStage: ${LoginStages[oldValue]} -> ${LoginStages[newValue]}`);
      if (newValue == LoginStages.StartOver) {
        console.log(`\tState Manager reset.`);
        this.stateManager = {} as StateManager;
      }
    }
  },

  data: () => ({
    allowAccessCodeSignIn: false,
    allowSelfSignUp: false,
    smsAccessCodePhoneNumber: "",

    // The following will control whether the controls on screen are disabled while we are processing.
    // processing: false,
    processingEmailEntry: false,
    processingSignInRequest: false,

    processingAccessCodeRequest: false,
    processingForgotPasswordRequest: false,
    processingFederatedIdentityRequest: false,
    processingSharedSecret: false,
    processingUpdatePassword: false,
    processingTermsAndConditionsApproval: false,
    processingAllowNotifications: false,

    loginStage: LoginStages.EnterEmailOnLoad as LoginStages,
    stateManager: {} as StateManager,
    signincountdown: 0,

    emailAddressOrPhoneNumber: "",

    password: "",
    newpassword: "",
    confirmnewpassword: "",
    accesscode: "",
    showPassword: false,

    enableNotifications: true,
    acceptTermsAndConditions: false,

    signinsuccessful: false,

    loginChallenge: null as LoginChallenge | null,
    accessCodeChallenge: null as AccessCodeChallenge | null
  }),

  computed: {
    unwatchedMethodNames(): string[] {
      return ["formatPhoneNumberForDisplay"];
    },
    verifiedContactMethods(): ContactMethod[] | undefined {
      return this.loginChallenge?.verifiedContactMethods?.filter(
        x => !!x.phoneNumberTail?.length || !!x.emailAddress?.length
      );
    },
    selectedContactMethodID: {
      get(): string | null | undefined {
        return this.accessCodeChallenge?.contactMethodID;
      },
      set(val: string | null | undefined) {
        if (!!this.loginChallenge) this.loginChallenge.contactMethodID = val ?? null;
        if (!!this.accessCodeChallenge) this.accessCodeChallenge.contactMethodID = val ?? null;
      }
    },
    hasAdditionalLoginMethods(): boolean {
      return !!this.loginChallenge?.verifiedContactMethods?.length;
    },
    // Brand Image Default Numbers
    brandImageWidth: {
      get(): number {
        return this.$store.getters.brandImageWidth;
      },
      set(val: number) {
        this.$store.commit("SET_BRAND_LOGO_WIDTH", val);
      }
    },

    processingData(): boolean {
      return (
        this.processing ||
        this.processingFederatedIdentityRequest ||
        this.processingEmailEntry ||
        this.processingSharedSecret ||
        this.processingAccessCodeRequest ||
        this.processingSignInRequest ||
        this.processingForgotPasswordRequest ||
        this.processingUpdatePassword
      );
    },
    showEmailEntry(): boolean {
      return this.showEmailEntryOnLoad || this.reshowEmailEntry;
    },
    showEmailEntryOnLoad(): boolean {
      return this.loginStage == LoginStages.EnterEmailOnLoad;
    },
    // Used to differentiate between email entry being shown on load vs going back to it, since restarting requires transitions for appearing
    reshowEmailEntry(): boolean {
      return this.loginStage == LoginStages.StartOver;
    },
    /** Whether the login password entry field should be displayed on the screen. */
    showPasswordEntry(): boolean {
      return this.loginStage == LoginStages.EnterPassword;
    },
    showAccessCodeEntry(): boolean {
      return this.loginStage == LoginStages.EnterAccessCode;
    },
    showAccessCodeEntryForLogin(): boolean {
      return (
        this.loginStage == LoginStages.EnterAccessCode &&
        !this.stateManager.didForgetPassword &&
        !this.stateManager.requiresActivation
      );
    },
    showAccessCodeEntryForForgotPassword(): boolean {
      return (
        this.loginStage == LoginStages.EnterAccessCode && !!this.stateManager.didForgetPassword
      );
    },
    showAccessCodeEntryForActivation(): boolean {
      return (
        this.loginStage == LoginStages.EnterAccessCode && !!this.stateManager.requiresActivation
      );
    },
    showAdditionalContactMethods(): boolean {
      return this.loginStage == LoginStages.SelectDifferentContactMethod;
    },
    showUpdatePasswordEntry(): boolean {
      return this.loginStage == LoginStages.EnterNewPassword;
    },
    showPasswordUpdatedSuccessfullyResponse(): boolean {
      return this.loginStage == LoginStages.NewPasswordUpdated;
    },
    showTermsAndConditions(): boolean {
      return this.loginStage == LoginStages.TermsAndConditions;
    },
    showAllowNotifications(): boolean {
      return this.loginStage == LoginStages.AllowNotifications;
    },
    showTermsAndConditionsAcceptedSuccessfully(): boolean {
      return this.loginStage == LoginStages.TermsAndConditionsAccepted;
    },

    languageslist(): any[] {
      return this.$store.state.languages.fullList.sort(function(a: any, b: any) {
        return a.number - b.number;
      });
    },

    language: {
      get(): any {
        return this.$store.state.language;
      },
      set(val: any) {
        if (val != undefined) {
          let language = this.$store.state.languages.fullList.find((x: any) => x.number == val);
          this.$store.commit("SET_PREFERRED_LANGUAGE", {
            shortCode: language.shortCode,
            shortCodeExt: language.shortCodeExt,
            number: language.number
          });
        }
      }
    },

    dialogPrivacy: {
      get(): boolean {
        return this.$store.state.dialogPrivacy;
      },
      set(val: boolean) {
        this.$store.commit("SET_PRIVACY_DIALOG", val);
      }
    },

    dialogTerms: {
      get(): boolean {
        return this.$store.state.dialogTerms;
      },
      set(val: boolean) {
        this.$store.commit("SET_TERMS_DIALOG", val);
      }
    }
  },

  methods: {
    /*
      GLOBAL
    */
    ...mapMutations({
      setShowAppBar: "SET_SHOW_APP_BAR",
      setShowDrawer: "SET_SHOW_DRAWER",
      setShowFooter: "SET_SHOW_FOOTER",
      setShowBottomBar: "SET_SHOW_BOTTOM_BAR",
      setPrivacyDialog: "SET_PRIVACY_DIALOG",
      setTermsDialog: "SET_TERMS_DIALOG",
      setPersistentTermsDialog: "SET_TERMS_DIALOG_PERSISTENT"
    }),

    async refreshUserReferenceData() {
      await Promise.all([this.$store.dispatch("LOAD_LANGUAGES")]);
    },

    startOver() {
      this.inlineMessage.message = "";
      this.loginStage = LoginStages.StartOver;

      // Keep email as it will probably be the same
      this.password = "";
      this.accesscode = "";
      this.newpassword = "";
      this.confirmnewpassword = "";

      this.processing = false;
    },

    // Navigate the logged-in user to the application
    loadIntoApplication() {
      this.loginStage = LoginStages.LoggingIn;
      this.setShowAppBar(true);
      this.setShowDrawer(true);
      this.setShowFooter(true);
      this.setShowBottomBar(true);
      let loginReturnPath = this.$store.state.loginReturnPath || "/?firstLoad=true";
      this.$store.commit("CLEAR_LOGIN_RETURN_PATH");
      this.$router.replace(loginReturnPath);
    },

    /*
      EMAIL ENTRY
    */

    // Method used in conjunction with the Save button.
    async typeYourPassword(e: Event) {
      e.preventDefault();

      if (!(this.$refs.enterEmailAddressForm as VForm).validate()) {
        return;
      }

      await this.getChallenge();
    },

    parseLockedUntilString(error: any) {
      if (!error?.details?.context?.lockedUntil?.length) return "";

      // We only display the time as minutes.  Therefore if we cut off the seconds, we're technically rounding down
      // To counteract this, add 1 minute, so if the time is 2:20:01 PM (which is after 2:20 PM) we show 2:21 PM instead.
      let lockedUntilDate = DateUtil.addMinutesToDate(
        new Date(error.details.context.lockedUntil),
        1
      );
      let now = new Date();
      if (lockedUntilDate < now) return "";

      let lockedUntil = this.$format.datetime(lockedUntilDate);
      if (this.$format.date(lockedUntilDate) == this.$format.date(now)) {
        lockedUntil = this.$format.time(lockedUntilDate);
      }
      return lockedUntil;
    },
    async getChallenge() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      let email = "";
      let phoneNumber = "";
      let identifier = "";
      if (this.rules.validPhoneNumber(this.emailAddressOrPhoneNumber) == true) {
        phoneNumber = this.emailAddressOrPhoneNumber;
      } else if (this.rules.validEmail(this.emailAddressOrPhoneNumber) == true) {
        email = this.emailAddressOrPhoneNumber;
      } else {
        identifier = this.emailAddressOrPhoneNumber;
      }

      this.processing = true;
      this.processingEmailEntry = true;
      try {
        let loginChallenge = await loginService.getLoginChallenge(email, phoneNumber, identifier);
        this.loginChallenge = loginChallenge;

        // After a brief moment set the focus on the Password Input box
        setTimeout(() => {
          this.processing = false;
          this.processingEmailEntry = false;
        }, 1000);

        if (!loginChallenge) {
          this.inlineMessage.message = this.$t("username-not-found");
          this.inlineMessage.type = "warning";
          return;
        } else if (loginChallenge.needsActivation) {
          this.stateManager.requiresActivation = true;
          this.accessCodeChallenge = await accessCodeService.activateUserInformation(
            this.loginChallenge!,
            email,
            phoneNumber
          );
          this.loginStage = LoginStages.EnterAccessCode;
          (this.$refs.accesscodeentry as any)?.clear();
          setTimeout(() => {
            console.log(`(this.$refs.accesscodeentry as any)?.focus();`);
            (this.$refs.accesscodeentry as any)?.focus();
          }, 4000);
          return;
        } else if (!loginChallenge.publicSalt) {
          this.stateManager.requiresActivation = true;
          // If there is no public salt, there is a user associated to the email/phone, but it doesn't have a password yet
          this.sendAccessCode();
          return;
        } else {
          this.loginStage = LoginStages.EnterPassword;
          return;
        }
      } catch (error) {
        // We want to keep using the error code sent by the server as our key
        // But if our error is due to multiple accounts or an unverified contact method we want it displayed as a warning
        let overrides = {
          "409": { type: "warning" },
          "412": { type: "warning" },
          "423": { errorMessageValues: [this.parseLockedUntilString(error)] }
        } as ErrorHandlingOverride;
        this.handleError(error, "unexpected-network-error-short", overrides);
      } finally {
        this.processing = false;
        this.processingEmailEntry = false;

        // After a brief moment set the focus on the Password Input box
        setTimeout(() => {
          if (this.$refs.passwordinput) {
            console.log(`(this.$refs.passwordinput as any).focus();`);
            (this.$refs.passwordinput as any).focus();
          }
        }, 500);
      }
    },

    /*
      PASSWORD ENTRY
    */
    startOverFromTypePassword() {
      this.startOver();
      this.processingEmailEntry = false;
    },

    async signin(e: Event) {
      e.preventDefault();
      if (!(this.$refs.enterPasswordForm as VForm).validate()) {
        return;
      }

      this.processing = true;
      this.processingSignInRequest = true;
      try {
        let encoder = new TextEncoder();
        let publicHash = await hmacSha512(
          this.loginChallenge!.publicSalt,
          encoder.encode(this.password)
        );
        let loginInformation = await loginService.respondToLoginChallenge(
          this.loginChallenge!,
          publicHash
        );
        if (loginInformation) {
          if (!!loginInformation.accessCodeChallenge) {
            this.accessCodeChallenge = loginInformation.accessCodeChallenge;
            this.stateManager.didForgetPassword = false;
            this.accesscode = "";
            this.loginStage = LoginStages.EnterAccessCode;
            this.focusAccessCodeField(4000);
            return;
          } else {
            await this.setLogin(loginInformation);
            this.checkTermsAndConditions(false);
            return;
          }
        } else {
          this.inlineMessage.message = this.$t("invalid-password");
          this.inlineMessage.type = "warning";
          // After a brief moment set the focus on the Password Input box
          // FYI you cannot use the "focus()" or "select()" immediately you need to use setTimeout or nextTick
          // Also getting at the appropriate input control within the vuetify text field is a little tricky.
          setTimeout(() => {
            if (this.$refs.passwordinput) {
              console.log(`(this.$refs.passwordinput as any).focus();`);
              (this.$refs.passwordinput as any).focus();
              (this.$refs.passwordinput as any).$el.querySelector("input").select();
            }
          }, 200);
        }
      } catch (error) {
        this.handleError(error, undefined, {
          "423": { errorMessageValues: [this.parseLockedUntilString(error)] }
        });
      } finally {
        this.processing = false;
        this.processingSignInRequest = false;
      }
    },

    /*
      ACCESS CODE
    */
    focusAccessCodeField(delayMS: number) {
      (this.$refs.accesscodeentry as any)?.clear();
      setTimeout(() => {
        if (!!this.$refs.accesscodeentry) {
          (this.$refs.accesscodeentry as any)?.focus();
        }
      }, delayMS);
    },

    startOverFromAccessCodeEntry() {
      this.startOver();

      (this.$refs.accesscodeentry as any)?.clear();

      this.processingForgotPasswordRequest = false;
      this.processingAccessCodeRequest = false;
      this.processingEmailEntry = false;
    },

    // This function is fired by the user clicking on the button that requests an "Access Code"
    async sendAccessCode(forgotPassword: boolean = false) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.processingAccessCodeRequest = true;

      try {
        this.accessCodeChallenge = await accessCodeService.generateAccessCode(
          this.loginChallenge!,
          this.accessCodeChallenge ?? undefined
        );

        this.stateManager.didForgetPassword = forgotPassword;
        this.loginStage = LoginStages.EnterAccessCode;
      } catch (error) {
        this.handleError(error, undefined, {
          "423": { errorMessageValues: [this.parseLockedUntilString(error)] }
        });
      } finally {
        this.processing = false;
        this.processingAccessCodeRequest = false;

        this.accesscode = "";
        this.focusAccessCodeField(4000);
      }
    },

    async resendAccessCode() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.processingAccessCodeRequest = true;

      try {
        await accessCodeService.resendAccessCode(this.accessCodeChallenge!);
      } catch (error) {
        this.handleError(error, undefined, {
          "423": { errorMessageValues: [this.parseLockedUntilString(error)] }
        });
      } finally {
        this.processing = false;
        this.processingAccessCodeRequest = false;

        this.accesscode = "";
        (this.$refs.accesscodeentry as any)?.clear();
        setTimeout(() => {
          console.log(`(this.$refs.accesscodeentry as any)?.focus();`);
          (this.$refs.accesscodeentry as any)?.focus();
        }, 4000);
      }
    },

    selectDifferentContactMethodForAccessCode() {
      this.loginStage = LoginStages.SelectDifferentContactMethod;
    },
    contactMethodSelected() {
      this.sendAccessCode(false);
    },

    // Event for when the last digit of the access code has been entered by the user
    codeEntered(codeString: string) {
      console.log(`codeEntered: ${codeString}`);
      this.accesscode = codeString;
      this.processAccessCode();
    },

    async processAccessCode() {
      console.log(`processAccessCode`);
      if (this.showAccessCodeEntryForForgotPassword || this.showAccessCodeEntryForLogin) {
        this.signinWithAccessCode();
      } else if (this.showAccessCodeEntryForActivation) {
        this.activateAccountViaAccessCode();
      }
    },

    async signinWithAccessCode() {
      console.log(`signinWithAccessCode`);
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.processingSignInRequest = true;
      try {
        this.accessCodeChallenge = await accessCodeService.loginWithAccessCode(
          this.accessCodeChallenge!,
          this.accesscode
        );

        let loginResult = this.accessCodeChallenge.loginInformation;
        if (loginResult) {
          await this.setLogin(loginResult);
          if (this.stateManager.didForgetPassword) {
            this.loginStage = LoginStages.EnterNewPassword;
          } else {
            this.checkTermsAndConditions(false);
          }
        } else {
          this.inlineMessage.message = this.$t("invalid-access-code");
          this.inlineMessage.type = "warning";
          // After a brief moment set the focus on the access code entry box
          this.accesscode = "";
          (this.$refs.accesscodeentry as any)?.clear();
          setTimeout(() => {
            console.log(`(this.$refs.accesscodeentry as any)?.focus();`);
            (this.$refs.accesscodeentry as any)?.focus();
          }, 200);
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        // These are to be uncommented when they can be properly setup against a real function
        this.processing = false;
        this.processingSignInRequest = false;
      }
    },

    /*
      VERIFY EMAIL ADDRESS OR PHONE NUMBER VIA ACCESS CODE
    */

    async setLogin(loginResult: LoginInformation) {
      console.log(`setLogin`);
      console.log(`\tloginResult: ${JSON.stringify(loginResult)}`);
      this.stateManager.isNewAccount = !loginResult.hasAcceptedTermsAndConditions;
      this.stateManager.requiresUpdatedTermsAndConditions =
        loginResult.requiresNewTermsAndConditions;
      console.log(`\tstateManager: ${JSON.stringify(this.stateManager)}`);
      await setLoginInformation(loginResult);
    },

    async activateAccountViaAccessCode() {
      console.log(`signinWithAccessCode`);
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.processingSignInRequest = true;
      try {
        this.accessCodeChallenge = await accessCodeService.activateAccountWithAccessCode(
          this.accessCodeChallenge!,
          this.accesscode
        );

        let loginResult = this.accessCodeChallenge.loginInformation;
        if (loginResult) {
          await this.setLogin(loginResult);
          this.loginStage = LoginStages.EnterNewPassword;
        } else {
          this.inlineMessage.message = this.$t("invalid-access-code");
          this.inlineMessage.type = "warning";
          // After a brief moment set the focus on the access code entry box
          this.accesscode = "";
          (this.$refs.accesscodeentry as any)?.clear();
          setTimeout(() => {
            console.log(`(this.$refs.accesscodeentry as any)?.focus();`);
            (this.$refs.accesscodeentry as any)?.focus();
          }, 200);
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        // These are to be uncommented when they can be properly setup against a real function
        this.processing = false;
        this.processingSignInRequest = false;
      }
    },

    /*
      FORGOT PASSWORD
    */
    startOverFromForgotPassword() {
      this.inlineMessage.message = "";
      this.loginStage = LoginStages.StartOver;

      this.processing = false;
      this.processingForgotPasswordRequest = false;
      this.processingAccessCodeRequest = false;
      this.processingEmailEntry = false;
    },

    // Once the password has been updated, login the user automatically
    decrementCountdownTimer() {
      this.signincountdown = 7;
      setInterval(() => {
        this.signincountdown--;
        if (this.signincountdown == 0) {
          this.loadIntoApplication();
        }
      }, 1000);
    },

    async updatePassword() {
      if (!(this.$refs.changePasswordForm as VForm).validate()) {
        return;
      }
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.processingUpdatePassword = true;

      try {
        let publicSalt = new Uint8Array(32);
        crypto.getRandomValues(publicSalt);
        let textEncoder = new TextEncoder();
        let publicHash = await hmacSha512(publicSalt, textEncoder.encode(this.newpassword));
        if (await loginService.updateUserPassword(publicSalt, publicHash)) {
          this.loginStage = LoginStages.NewPasswordUpdated;

          this.checkTermsAndConditions(true);
          await pause(2000); // Do the animation before turning off the processing flags
        } else {
          this.inlineMessage.message = this.$t("password.update-password-reset-expired");
          this.inlineMessage.type = "warning";
        }
      } catch (error) {
        this.handleError(error, undefined, {
          "423": { errorMessageValues: [this.parseLockedUntilString(error)] }
        });
      } finally {
        this.processing = false;
        this.processingUpdatePassword = false;
      }
    },
    checkTermsAndConditions(performCountdown: boolean) {
      if (this.stateManager.requiresUpdatedTermsAndConditions || this.stateManager.isNewAccount) {
        console.log(`show terms and conditions`);
        this.loginStage = LoginStages.TermsAndConditions;
      } else {
        console.log(`T&C not required, log in`);
        if (performCountdown) this.decrementCountdownTimer();
        else this.loadIntoApplication();
      }
      this.stateManager.hasCheckedTermsAndConditions = true;
    },
    async processTermsAndConditionsAcceptance() {
      if (!this.acceptTermsAndConditions) return;

      try {
        this.processingTermsAndConditionsApproval = true;
        await personService.acceptCurrentTermsAndConditions();

        await getLogin(true);
        if (!!this.stateManager.isNewAccount) {
          this.loginStage = LoginStages.AllowNotifications;
        } else {
          this.loginStage = LoginStages.TermsAndConditionsAccepted;
          this.decrementCountdownTimer();
        }
      } catch (e) {
        this.handleError(e as Error);
      } finally {
        this.processingTermsAndConditionsApproval = false;
      }
    },
    async processAllowNotifications() {
      if (!this.acceptTermsAndConditions) return;

      try {
        this.processingAllowNotifications = true;
        await notificationService.setAllowNotificationsContactMethods(
          this.verifiedContactMethods ?? []
        );

        this.loginStage = LoginStages.TermsAndConditionsAccepted;
        getLogin(true);
        this.decrementCountdownTimer();
      } catch {
      } finally {
        this.processingAllowNotifications = false;
      }
    },

    formatPhoneNumberForDisplay(rawPhoneNumber: string | null | undefined): string {
      if (!rawPhoneNumber) return "";

      let sanitizedPhoneNumber = rawPhoneNumber.replace("+", "");
      if (sanitizedPhoneNumber.length < 7) return "";

      let last4 = sanitizedPhoneNumber.slice(-4);
      let first3 = sanitizedPhoneNumber.slice(-7, -4);
      let displayPhoneNumber = `${first3}-${last4}`;
      if (sanitizedPhoneNumber.length >= 10) {
        let areaCode = sanitizedPhoneNumber.slice(-10, -7);
        displayPhoneNumber = `(${areaCode}) ${displayPhoneNumber}`;
      }
      if (sanitizedPhoneNumber.length > 10) {
        let countryCodeLength = sanitizedPhoneNumber.length - 10;
        let countryCode = sanitizedPhoneNumber.slice(0, countryCodeLength);
        countryCode = countryCode.replace("+", "");
        displayPhoneNumber = `+${countryCode} ${displayPhoneNumber}`;
      }
      return displayPhoneNumber;
    }
  },

  created: async function() {
    // Tells the store to hide the various UI bars and drawers as appropriate.
    this.setShowAppBar(false);
    this.setShowDrawer(false);
    this.setShowFooter(false);
    this.setShowBottomBar(false);

    //Refresh any dependency data in the store.
    this.refreshUserReferenceData();

    // After a couple seconds set the focus on the Username Input box
    const interval = setTimeout(() => {
      if (this.$refs.usernameinput) {
        (this.$refs.usernameinput as any).focus();
      }
    }, 1500);

    let curEnvironment = await environmentService.getCurrent();
    this.allowAccessCodeSignIn = !(curEnvironment.loginRequiresPasswordAndCode ?? false);
    this.allowSelfSignUp = curEnvironment.allowSelfSignUp ?? false;
    this.smsAccessCodePhoneNumber = curEnvironment.smsAccessCodeFromPhoneNumber;
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
    window.removeEventListener("scroll", this.scroll);
  }
});

