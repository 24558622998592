import "@fd/lib/client-util/polyfill";
import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import { registerOptions } from "@fd/lib/vue";
import FdIndex from "./views/SP.Index.vue";
import "../../common/client/assets/scss/style.scss";
import "./assets/scss/style.scss";

import options from "./options";
registerOptions(options);

const vue = new Vue({
  ...options,
  render: function(h) {
    return h(FdIndex);
  }
});

declare function runSplashScreenCloseAnim(): Promise<void>;
(async function () {
  await runSplashScreenCloseAnim();
  vue.$mount("#app-root");
})();
