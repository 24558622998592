import FDVue from "@fd/lib/vue";

import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import {
  AccessCodeChallenge,
  LoginChallenge,
  accessCodeService,
  loginService,
  LoginInformation
} from "@fd/current/client/services";
import { hmacSha512 } from "@fd/lib/client-util/encryption";
import { ErrorHandlingOverride } from "@fd/lib/vue/mixins/serviceErrorHandling";
import { setLogin as setLoginInformation } from "@fd/current/client/login";

enum ReauthStages {
  EnterPassword,
  EnterAccessCodeOriginal,
  EnterAccessCodeResend
}
const ReauthenticateDialog = FDVue.extend({
  name: "fd-reauthenticate-dialog",
  mixins: [dialogSupport, rules],

  components: {
    "fd-code-entry": () => import("@fd/lib/vue/components/CodeEntry.vue")
  },

  data() {
    return {
      reauthStage: ReauthStages.EnterPassword as ReauthStages,
      processingEmailEntry: false,
      processingSignInRequest: false,
      processingAccessCodeRequest: false,
      dialogOpen: false,

      loginChallenge: null as LoginChallenge | null,
      accessCodeChallenge: null as AccessCodeChallenge | null,

      username: "",
      password: "",
      accesscode: "",
      showPassword: false
    };
  },

  computed: {
    showUseAccessCodeButton(): boolean {
      return this.showPasswordEntry && this.allowAccessCodeSignIn;
    },
    showUsePasswordButton(): boolean {
      return this.showAccessCodeEntry && this.allowAccessCodeSignIn;
    },
    showGoBackToPasswordButton(): boolean {
      return this.showAccessCodeEntry && !this.allowAccessCodeSignIn;
    },
    allowAccessCodeSignIn(): boolean {
      return !(this.$store.state.curEnvironment?.loginRequiresPasswordAndCode ?? false);
    },
    smsAccessCodePhoneNumber(): boolean {
      return !(this.$store.state.curEnvironment?.smsAccessCodeFromPhoneNumber ?? "");
    },
    showPasswordEntry(): boolean {
      return this.reauthStage == ReauthStages.EnterPassword;
    },
    showAccessCodeEntry(): boolean {
      return (
        this.reauthStage == ReauthStages.EnterAccessCodeOriginal ||
        this.reauthStage == ReauthStages.EnterAccessCodeResend
      );
    },
    accessCodeIsOriginal(): boolean {
      return this.reauthStage == ReauthStages.EnterAccessCodeOriginal;
    },
    accessCodeIsResent(): boolean {
      return this.reauthStage == ReauthStages.EnterAccessCodeResend;
    },
    accessCodeSentToAnyEmailAddresses(): boolean {
      return !!this.accessCodeChallenge?.sentToEmailAddressCount;
    },
    accessCodeSentToExaclyOneEmailAddress(): boolean {
      return this.accessCodeChallenge?.sentToEmailAddressCount;
    },
    accessCodeSentToAnyPhoneNumbers(): boolean {
      return !!this.accessCodeChallenge?.sentToPhoneNumberCount;
    },
    accessCodeSentToExaclyOnePhoneNumber(): boolean {
      return this.accessCodeChallenge?.sentToPhoneNumberCount;
    }
  },

  methods: {
    // *** OPEN & LOAD ***
    async open(username: string): Promise<boolean> {
      this.optOutOfErrorHandling();
      this.username = username;

      // Wait for the challenge to successfully come back
      // If there's an error while getting the challenge and we quit to the login screen, we don't want the reauth dialog to appear before the navigation occurs
      if (!(await this.getChallenge())) return false;

      return await this.showDialogWithAction(() => {
        this.dialogOpen = true;
      });
    },

    async getChallenge(): Promise<boolean> {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      let email = "";
      let phoneNumber = "";
      let identifier = "";
      if (this.rules.validPhoneNumber(this.username) == true) {
        phoneNumber = this.username;
      } else if (this.rules.validEmail(this.username) == true) {
        email = this.username;
      } else {
        identifier = this.username;
      }

      this.processing = true;
      this.processingEmailEntry = true;
      try {
        let loginChallenge = await loginService.getLoginChallenge(email, phoneNumber, identifier);
        this.loginChallenge = loginChallenge;

        // After a brief moment set the focus on the Password Input box
        setTimeout(() => {
          this.processing = false;
          this.processingEmailEntry = false;
        }, 1000);

        if (!loginChallenge) {
          this.quitBackToLogin();
          return false;
        } else if (loginChallenge.needsActivation) {
          this.quitBackToLogin();
          return false;
        } else if (!loginChallenge.publicSalt) {
          this.quitBackToLogin();
          return false;
        } else {
          this.reauthStage = ReauthStages.EnterPassword;
          this.focusPasswordField(500);
          return true;
        }
      } catch (error) {
        this.quitBackToLogin();
        return false;
      } finally {
        this.processing = false;
        this.processingEmailEntry = false;
      }
    },

    quitBackToLogin() {
      if (this.dialogOpen) this.closeDialog(false);
      this.$router.push("/login");
    },
    async setLogin(loginResult: LoginInformation) {
      await setLoginInformation(loginResult);
      this.closeDialog(true);
    },
    async signin(e: Event) {
      if (this.showPasswordEntry) {
        this.signInWithPassword(e);
      } else if (this.showAccessCodeEntry) {
        this.signinWithAccessCode();
      }
    },

    // *** PASSWORD FORM ***
    focusPasswordField(delayMS: number) {
      // After a brief moment set the focus on the Password Input box
      // FYI you cannot use the "focus()" or "select()" immediately you need to use setTimeout or nextTick
      // Also getting at the appropriate input control within the vuetify text field is a little tricky.
      setTimeout(() => {
        if (this.$refs.passwordinput) {
          (this.$refs.passwordinput as any).focus();
          (this.$refs.passwordinput as any).$el.querySelector("input").select();
        }
      }, delayMS);
    },
    async signInWithPassword(e: Event) {
      e.preventDefault();
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.processingSignInRequest = true;
      try {
        let encoder = new TextEncoder();
        let publicHash = await hmacSha512(
          this.loginChallenge!.publicSalt,
          encoder.encode(this.password)
        );
        let loginInformation = await loginService.respondToLoginChallenge(
          this.loginChallenge!,
          publicHash
        );
        if (loginInformation) {
          if (!!loginInformation.accessCodeChallenge) {
            this.accessCodeChallenge = loginInformation.accessCodeChallenge;
            this.accesscode = "";
            this.reauthStage = ReauthStages.EnterAccessCodeOriginal;
            this.focusAccessCodeField(4000);
            return;
          } else {
            this.setLogin(loginInformation);
            return;
          }
        } else {
          this.inlineMessage.message = this.$t("invalid-password");
          this.inlineMessage.type = "warning";
          this.focusPasswordField(200);
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.processingSignInRequest = false;
      }
    },

    // *** ACCESS CODE FORM ***
    focusAccessCodeField(delayMS: number) {
      (this.$refs.accesscodeentry as any)?.clear();
      setTimeout(() => {
        if (!!this.$refs.accesscodeentry) {
          (this.$refs.accesscodeentry as any)?.focus();
        }
      }, delayMS);
    },
    // This function is fired by the user clicking on the button that requests an "Access Code"
    async sendAccessCode() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.processingAccessCodeRequest = true;

      try {
        this.accessCodeChallenge = await accessCodeService.generateAccessCode(
          this.loginChallenge!,
          this.accessCodeChallenge ?? undefined
        );

        this.password = "";
        this.accesscode = "";

        this.reauthStage = ReauthStages.EnterAccessCodeOriginal;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.processingAccessCodeRequest = false;

        this.accesscode = "";
        this.focusAccessCodeField(4000);
      }
    },

    async resendAccessCode() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.processingAccessCodeRequest = true;

      try {
        await accessCodeService.resendAccessCode(this.accessCodeChallenge!);
        this.reauthStage = ReauthStages.EnterAccessCodeResend;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.processingAccessCodeRequest = false;

        this.accesscode = "";
        this.focusAccessCodeField(4000);
      }
    },
    // Event for when the last digit of the access code has been entered by the user
    codeEntered(codeString: string) {
      this.accesscode = codeString;
      this.signinWithAccessCode();
    },
    async signinWithAccessCode() {
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.processingSignInRequest = true;
      try {
        this.accessCodeChallenge = await accessCodeService.loginWithAccessCode(
          this.accessCodeChallenge!,
          this.accesscode
        );

        let loginResult = this.accessCodeChallenge.loginInformation;
        if (loginResult) {
          this.setLogin(loginResult);
        } else {
          this.inlineMessage.message = this.$t("invalid-access-code");
          this.inlineMessage.type = "warning";
          // After a brief moment set the focus on the access code entry box
          this.accesscode = "";
          this.focusAccessCodeField(200);
        }
      } catch (error) {
        let overrides = {
          "422": { errorMessageKey: "access-code-expired" }
        } as ErrorHandlingOverride;
        this.handleError(error, undefined, overrides);
      } finally {
        // These are to be uncommented when they can be properly setup against a real function
        this.processing = false;
        this.processingSignInRequest = false;
      }
    },

    async goBackToPassword() {
      this.inlineMessage.message = "";
      this.reauthStage = ReauthStages.EnterPassword;

      this.password = "";
      this.accesscode = "";

      this.processing = false;

      // After a brief moment set the focus on the Password Input box
      this.focusPasswordField(500);
    }
  }
});

export default ReauthenticateDialog;

export async function showReauthentication(username: string): Promise<boolean> {
  let dialog = createDialog(ReauthenticateDialog);
  // if (!username?.length) username = "craig@formidabledesigns.com";
  // if (!username?.length) username = "7809161356";
  return await dialog.open(username);
}

